export const AgentNarratorItem = () => import('../../components/ui/AgentNarratorItem.vue' /* webpackChunkName: "components/agent-narrator-item" */).then(c => wrapFunctional(c.default || c))
export const BigLinks = () => import('../../components/ui/BigLinks.vue' /* webpackChunkName: "components/big-links" */).then(c => wrapFunctional(c.default || c))
export const Blogpost = () => import('../../components/ui/Blogpost.vue' /* webpackChunkName: "components/blogpost" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/ui/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const Demo = () => import('../../components/ui/Demo.vue' /* webpackChunkName: "components/demo" */).then(c => wrapFunctional(c.default || c))
export const DemoInsert = () => import('../../components/ui/DemoInsert.vue' /* webpackChunkName: "components/demo-insert" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/ui/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const FaqItem = () => import('../../components/ui/FaqItem.vue' /* webpackChunkName: "components/faq-item" */).then(c => wrapFunctional(c.default || c))
export const ListOrderOption = () => import('../../components/ui/ListOrderOption.vue' /* webpackChunkName: "components/list-order-option" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterInside = () => import('../../components/ui/LoginRegisterInside.vue' /* webpackChunkName: "components/login-register-inside" */).then(c => wrapFunctional(c.default || c))
export const Music = () => import('../../components/ui/Music.vue' /* webpackChunkName: "components/music" */).then(c => wrapFunctional(c.default || c))
export const Narrator = () => import('../../components/ui/Narrator.vue' /* webpackChunkName: "components/narrator" */).then(c => wrapFunctional(c.default || c))
export const Narratorcomp = () => import('../../components/ui/Narratorcomp.vue' /* webpackChunkName: "components/narratorcomp" */).then(c => wrapFunctional(c.default || c))
export const Narratorcomps = () => import('../../components/ui/Narratorcomps.vue' /* webpackChunkName: "components/narratorcomps" */).then(c => wrapFunctional(c.default || c))
export const NarratorsFilter = () => import('../../components/ui/NarratorsFilter.vue' /* webpackChunkName: "components/narrators-filter" */).then(c => wrapFunctional(c.default || c))
export const NarratorsList = () => import('../../components/ui/NarratorsList.vue' /* webpackChunkName: "components/narrators-list" */).then(c => wrapFunctional(c.default || c))
export const OrderClientItem = () => import('../../components/ui/OrderClientItem.vue' /* webpackChunkName: "components/order-client-item" */).then(c => wrapFunctional(c.default || c))
export const OrderNarratorItem = () => import('../../components/ui/OrderNarratorItem.vue' /* webpackChunkName: "components/order-narrator-item" */).then(c => wrapFunctional(c.default || c))
export const OrderOption = () => import('../../components/ui/OrderOption.vue' /* webpackChunkName: "components/order-option" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/ui/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/ui/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Sort = () => import('../../components/ui/Sort.vue' /* webpackChunkName: "components/sort" */).then(c => wrapFunctional(c.default || c))
export const TicketMessage = () => import('../../components/ui/TicketMessage.vue' /* webpackChunkName: "components/ticket-message" */).then(c => wrapFunctional(c.default || c))
export const NarratorDemoInside = () => import('../../components/profile_settings/NarratorDemoInside.vue' /* webpackChunkName: "components/narrator-demo-inside" */).then(c => wrapFunctional(c.default || c))
export const NarratorPricesInside = () => import('../../components/profile_settings/NarratorPricesInside.vue' /* webpackChunkName: "components/narrator-prices-inside" */).then(c => wrapFunctional(c.default || c))
export const NarratorSettingsInside = () => import('../../components/profile_settings/NarratorSettingsInside.vue' /* webpackChunkName: "components/narrator-settings-inside" */).then(c => wrapFunctional(c.default || c))
export const MainFooter = () => import('../../components/MainFooter.vue' /* webpackChunkName: "components/main-footer" */).then(c => wrapFunctional(c.default || c))
export const MainHeader = () => import('../../components/MainHeader.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c))
export const MainSidebar = () => import('../../components/MainSidebar.vue' /* webpackChunkName: "components/main-sidebar" */).then(c => wrapFunctional(c.default || c))
export const Modals = () => import('../../components/Modals.vue' /* webpackChunkName: "components/modals" */).then(c => wrapFunctional(c.default || c))
export const SiteClosed = () => import('../../components/SiteClosed.vue' /* webpackChunkName: "components/site-closed" */).then(c => wrapFunctional(c.default || c))
export const MessageAlienationNeeded = () => import('../../components/message/AlienationNeeded.vue' /* webpackChunkName: "components/message-alienation-needed" */).then(c => wrapFunctional(c.default || c))
export const MessageCleaningNeeded = () => import('../../components/message/CleaningNeeded.vue' /* webpackChunkName: "components/message-cleaning-needed" */).then(c => wrapFunctional(c.default || c))
export const MessageClientWriteAfterCompletion = () => import('../../components/message/ClientWriteAfterCompletion.vue' /* webpackChunkName: "components/message-client-write-after-completion" */).then(c => wrapFunctional(c.default || c))
export const MessageFederalDone = () => import('../../components/message/FederalDone.vue' /* webpackChunkName: "components/message-federal-done" */).then(c => wrapFunctional(c.default || c))
export const NewOrderAgreementCheckbox = () => import('../../components/new_order/AgreementCheckbox.vue' /* webpackChunkName: "components/new-order-agreement-checkbox" */).then(c => wrapFunctional(c.default || c))
export const NewOrderGenreGuideLink = () => import('../../components/new_order/GenreGuideLink.vue' /* webpackChunkName: "components/new-order-genre-guide-link" */).then(c => wrapFunctional(c.default || c))
export const NewOrderGenreSelect = () => import('../../components/new_order/GenreSelect.vue' /* webpackChunkName: "components/new-order-genre-select" */).then(c => wrapFunctional(c.default || c))
export const ModalAgentNarratorChange = () => import('../../components/modal/AgentNarratorChange.vue' /* webpackChunkName: "components/modal-agent-narrator-change" */).then(c => wrapFunctional(c.default || c))
export const ModalCalcOrderNotLogged = () => import('../../components/modal/CalcOrderNotLogged.vue' /* webpackChunkName: "components/modal-calc-order-not-logged" */).then(c => wrapFunctional(c.default || c))
export const ModalClientSettings = () => import('../../components/modal/ClientSettings.vue' /* webpackChunkName: "components/modal-client-settings" */).then(c => wrapFunctional(c.default || c))
export const ModalClientTopUpWallet = () => import('../../components/modal/ClientTopUpWallet.vue' /* webpackChunkName: "components/modal-client-top-up-wallet" */).then(c => wrapFunctional(c.default || c))
export const ModalHowItWorks = () => import('../../components/modal/HowItWorks.vue' /* webpackChunkName: "components/modal-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const ModalLogin = () => import('../../components/modal/Login.vue' /* webpackChunkName: "components/modal-login" */).then(c => wrapFunctional(c.default || c))
export const ModalMusic = () => import('../../components/modal/Music.vue' /* webpackChunkName: "components/modal-music" */).then(c => wrapFunctional(c.default || c))
export const ModalNarratorSettings = () => import('../../components/modal/NarratorSettings.vue' /* webpackChunkName: "components/modal-narrator-settings" */).then(c => wrapFunctional(c.default || c))
export const ModalNarratorSettingsDemo = () => import('../../components/modal/NarratorSettingsDemo.vue' /* webpackChunkName: "components/modal-narrator-settings-demo" */).then(c => wrapFunctional(c.default || c))
export const ModalNarratorSettingsPrices = () => import('../../components/modal/NarratorSettingsPrices.vue' /* webpackChunkName: "components/modal-narrator-settings-prices" */).then(c => wrapFunctional(c.default || c))
export const ModalNarratorWithdraw = () => import('../../components/modal/NarratorWithdraw.vue' /* webpackChunkName: "components/modal-narrator-withdraw" */).then(c => wrapFunctional(c.default || c))
export const ModalNarrators = () => import('../../components/modal/Narrators.vue' /* webpackChunkName: "components/modal-narrators" */).then(c => wrapFunctional(c.default || c))
export const ModalNewTicket = () => import('../../components/modal/NewTicket.vue' /* webpackChunkName: "components/modal-new-ticket" */).then(c => wrapFunctional(c.default || c))
export const ModalNotify = () => import('../../components/modal/Notify.vue' /* webpackChunkName: "components/modal-notify" */).then(c => wrapFunctional(c.default || c))
export const ModalOrderOptions = () => import('../../components/modal/OrderOptions.vue' /* webpackChunkName: "components/modal-order-options" */).then(c => wrapFunctional(c.default || c))
export const ModalPause = () => import('../../components/modal/Pause.vue' /* webpackChunkName: "components/modal-pause" */).then(c => wrapFunctional(c.default || c))
export const ModalPayNotLogged = () => import('../../components/modal/PayNotLogged.vue' /* webpackChunkName: "components/modal-pay-not-logged" */).then(c => wrapFunctional(c.default || c))
export const ModalRegister = () => import('../../components/modal/Register.vue' /* webpackChunkName: "components/modal-register" */).then(c => wrapFunctional(c.default || c))
export const ModalReviews = () => import('../../components/modal/Reviews.vue' /* webpackChunkName: "components/modal-reviews" */).then(c => wrapFunctional(c.default || c))
export const ModalSaveDraft = () => import('../../components/modal/SaveDraft.vue' /* webpackChunkName: "components/modal-save-draft" */).then(c => wrapFunctional(c.default || c))
export const ModalSoundengineerSettings = () => import('../../components/modal/SoundengineerSettings.vue' /* webpackChunkName: "components/modal-soundengineer-settings" */).then(c => wrapFunctional(c.default || c))
export const ProfileOrdersMenu = () => import('../../components/profile/OrdersMenu.vue' /* webpackChunkName: "components/profile-orders-menu" */).then(c => wrapFunctional(c.default || c))
export const ProfileSupportMenu = () => import('../../components/profile/SupportMenu.vue' /* webpackChunkName: "components/profile-support-menu" */).then(c => wrapFunctional(c.default || c))
export const ProfileTelegramConnection = () => import('../../components/profile/TelegramConnection.vue' /* webpackChunkName: "components/profile-telegram-connection" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
